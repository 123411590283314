<template>
  <div class="main">
    <!-- 面包屑导航 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品生产信息</el-breadcrumb-item>
      <el-breadcrumb-item>上传产品生产信息</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图区 -->

    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入品种名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加品种信息</el-button
          >
        </el-col>
      </el-row>
      <!-- 数据列表区域 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="品种名称" prop="name"></el-table-column>
        <el-table-column label="品种年限" prop="year"></el-table-column>
        <el-table-column label="品种分类" prop="class"></el-table-column>
        <el-table-column label="品种编号" prop="number"></el-table-column>
        <el-table-column label="品种归属" prop="company"></el-table-column>
        <el-table-column label="文件号" prop="file_number"></el-table-column>
        <!-- <el-table-column label="品种简介" prop="content"></el-table-column> -->
        <el-table-column label="操作">
          <!-- 插槽自定义样式 -->
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="查看" placement="top-start">
              <el-button
                @click="getInfo(scope.row)"
                type="primary"
                icon="el-icon-view"
              ></el-button>
            </el-tooltip>
            <!-- <el-tooltip effect="dark" content="删除" placement="top-end">
              <el-button
                type="warning"
                @click="delInfo(scope.row.bh)"
                icon="el-icon-delete"
              ></el-button>
            </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!-- 新增产品生产dialog -->
      <el-dialog
        title="添加品种信息"
        :visible.sync="addDialogVisible"
        width="40%"
        @closed="closedialog"
      >
        <!-- 表单区 -->
        <el-form
          size="medium"
          ref="addFormRef"
          :model="addFormInfo"
          label-width="120px"
        >
          <!-- 下拉框 -->
          <el-form-item label="品种名称" prop="scz">
            <el-input
              v-model="addFormInfo.name"
              placeholder="例如：华科玉338"
            ></el-input>
          </el-form-item>
          <el-form-item label="种植区域" prop="scz">
            <el-input
              v-model="addFormInfo.area"
              placeholder="例如：河北"
            ></el-input>
          </el-form-item>

          <el-form-item label="年份" prop="year">
            <el-date-picker
              value-format="yyyy"
              format="yyyy"
              v-model="addFormInfo.year"
              type="year"
              placeholder="选择年份"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="审定编号" prop="number">
            <el-input
              v-model="addFormInfo.number"
              placeholder="例如：鄂引种2021090"
            ></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="class">
            <el-input
              v-model="addFormInfo.class"
              placeholder="例如：玉米"
            ></el-input>
          </el-form-item>
          <el-form-item label="选育单位" prop="company">
            <el-input
              v-model="addFormInfo.company"
              placeholder="例如：河北XXXX有限公司"
            ></el-input>
          </el-form-item>
          <el-form-item label="公告或文件号" prop="file_number">
            <el-input
              v-model="addFormInfo.file_number"
              placeholder="例如：鄂农发[2021]32号"
            ></el-input>
          </el-form-item>
          <el-form-item label="详细信息">
            <el-input type="textarea" v-model="addFormInfo.content"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addFormSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rules: {
        chandi: [{ requir: true, message: "请选择产地名称", trigger: "blur" }],
        cpmc: [{ requir: true, message: "请选择产品名称", trigger: "blur" }],
        scz: [{ requir: true, message: "请输入生产负责人", trigger: "blur" }],
        rq: [{ requir: true, message: "请选择日期", trigger: "blur" }],
      },
      tokenStr: "",
      queryInfo: {
        token: "",
        query: "",
        page: 1,
        pageSize: 8,
      },
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        token: "",
        name: "",
        area: "",
        year: "",
        class: "",
        number: "",
        company: "",
        file_number: "",
        content: "",
      },

      selectchandi: [],
      selectProduct: [],
    };
  },
  created() {
    this.tokenStr = window.sessionStorage.getItem("token");
    this.getDataList();
  },
  methods: {
    getInfo(row) {
      console.log(row);
      let datarow = JSON.parse(JSON.stringify(row));
      this.addDialogVisible = true;
      this.addFormInfo = datarow;
    },
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("pinzhong", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
      //  新增产地，下拉框数据
      // const {data:selectRes} =await this.$http.post('SelectPlace',{token:this.tokenStr})
      // this.selectchandi = selectRes.data;
      //  //  产品名称，下拉框数据
      // const {data: selectProductRes} =await this.$http.post('SelectProduction',{token:this.tokenStr})
      // this. selectProduct =  selectProductRes.data;
      // console.log(selectProductRes)
    },
    // 分页功能--监听当前页码值 改变事件
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
      // this.getDataList();
    },
    addFormSubmit() {
      this.addFormInfo.token = this.tokenStr;
      this.$http.post("addPinZhong", this.addFormInfo).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message.success("添加信息成功");
          this.getDataList();
        }
      });
      this.addDialogVisible = false;
      this.getDataList();
    },
    //   async delInfo(id){
    //     const confirmRes =await this.$confirm('此操作将永久删除该条信息, 是否继续?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).catch(err=>err)
    //     if(confirmRes !='confirm'){
    //       return this.$message.info('取消删除')
    //     }
    //     const res = await this.$http.post('DelProductionInfo',{
    //         token:this.tokenStr,bh:id
    //       })
    //     if(res.status !== 200){
    //       return this.$message.error('删除失败')
    //     }
    //     this.$message.success('删除成功')
    //     this.getDataList();
    //     console.log(res)
    // }
  },
};
</script>

<style scoped lang="less">
.el-breadcrumb {
  height: 30px;
}
.el-pagination {
  margin-top: 10px;
}
.main {
  background-color: #000d4a;
}
// .box-card{
//    background-color: #000d4a;
// }
</style>

